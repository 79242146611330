/* RESPONSIBLE TEAM: team-data-foundations */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable no-restricted-imports */
import ApplicationAdapter from 'embercom/adapters/application';
import { getApp } from 'embercom/lib/container-lookup';

export default ApplicationAdapter.extend({
  urlForDeleteRecord(id, modelName, snapshot) {
    let url = this._super(id, modelName, snapshot);
    let app = getApp();
    if (app) {
      let currentAdminId = app.get('currentAdmin.id');
      let name = snapshot.attributes().name;
      return `${url}?name=${encodeURIComponent(name)}&admin_id=${currentAdminId}`;
    }
    return url;
  },
});
